<template>
  <page-header-wrapper>
    <div class="a-card">
      <div>
        <CampaignSearch
          @resetData="resetSearchForm"
          @handleAdd="handleAdd"/>
        <div class="table-operator">
          <a-button type="dashed" @click="tableOption">{{ (optionAlertShow && '关闭') || '开启' }} alert</a-button>
        </div>
        <s-table
          :bordered="true"
          ref="table"
          :dataSource="data"
          :data="loadData"
          :alert="options.alert"
          :rowSelection="options.rowSelection"
          :pagination="{showSizeChanger: true, showQuickJumper: true, pageSize:20, total: 50}"
          rowKey="campaignId"
          :scroll="{ x: true}"
        >
          <a-table-column title="操作" align="center">
            <span slot-scope="record">
              <a @click="handleEdit(record)">编辑</a>
            </span>
          </a-table-column>
           <!-- 促销编码 -->
          <a-table-column :title="$t('columnName.campaignCode')" align="left">
            <template slot-scope="record">{{record.campaignCode}}</template>
          </a-table-column>
           <!-- 促销名称 -->
          <a-table-column :title="$t('columnName.campaignName')" align="left">
            <template slot-scope="record">{{record.campaignName}}</template>
          </a-table-column>
          <!-- 市场编码 -->
          <a-table-column :title="$t('columnName.marketplaceCode')" align="left">
            <template slot-scope="record">{{ record.marketplaceCode }} - {{ record.marketplaceName }}</template>
          </a-table-column>
          <!-- 创建人 -->
          <a-table-column :title="$t('columnName.creatorName')" align="left">
            <template slot-scope="record">{{record.creatorName}}<br />{{formatDateTime(record.createTime)}}</template>
          </a-table-column>
          <!-- 更新人 -->
          <a-table-column :title="$t('columnName.updaterName')" align="left">
            <template slot-scope="record">{{record.updaterName}}<br />{{formatDateTime(record.updateTime)}}</template>
          </a-table-column>
        </s-table>
      </div>
      <CommentEditor ref="commentEditor" @handleOk="handleCommentEditOk"/>
      <ApprovalEditor ref="approvalEditor" @handleOk="handleApprovalEditOk"/>
      <a-drawer width="70%" placement="right" :closable="false" :visible="showDetailDrawer" @close="onDetailDrawerClose">
        <CampaignDetail ref="campaignDetail" :options="detailOptions"></CampaignDetail>
      </a-drawer>
    </div>
  </page-header-wrapper>
</template>

<script>
// 演示如何使用 this.$dialog 封装 modal 组件
import {
  approvalCampaign,
  disableCampaign,
  enableCampaign,
  saveCampaignData,
  searchCampaign
} from '@/api/marketing/campaign'
//          import { saveCampaign } from '@/api/manage'
import { STable } from '@/components'
import ApprovalEditor from '@/components/approval/ApprovalEditor'
import CampaignSearch from '@/views/marketing/CampaignSearch'
import CampaignDetail from '@/views/marketing/CampaignDetail'
import CommentEditor from '@/components/comment/CommentEditor'

export default {
  name: 'CampaignList',
  components: {
    CommentEditor,
    ApprovalEditor,
    STable,
    CampaignSearch,
    CampaignDetail
  },
  data () {
    return {
      confirmEditLoading: false,
      // 查询参数
      queryParam: {},
      data: [],
      status: 'all',
      // 加载数据方法 必须为 Promise 对象
      // loadData: parameter => {
      //   console.log('loadData.parameter', parameter)
      //   return getCampaign(Object.assign(parameter, this.queryParam))
      //       .then(res => {
      //         return res.result
      //       })
      // },
      selectedRowKeys: [],
      selectedRows: [],
      campaignModalShow: false,
      campaignData: {
        condition: {},
        discount: {}
      },
      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false,
      detailOptions: {},
      showDetailDrawer: false
    }
  },
  created () {
    this.tableOption()
    // getRoleList({t: new Date()})
  },
  mounted () {
  },
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData (parameter) {
      return searchCampaign(Object.assign(parameter, this.queryParam)).then((res) => {
        console.log('loadData->getCampaignList.parameter', parameter, res)
        return res
      })
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd (record) {
      // 跳转到编辑页面
      this.$router.push({
        path: '/marketing/CampaignEdit',
        name: 'CampaignEdit',
        query: {
          type: 'add'
        }
      })
    },
    handleEdit (record) {
      // 跳转到编辑页面
      this.$router.push({
        path: '/marketing/CampaignEdit',
        name: 'CampaignEdit',
        query: {
          type: 'edit',
          campaignId: record.campaignId
        }
      })
    },
    handleDetail (record) {
      this.detailOptions = {
        campaignId: record.campaignId
      }
      this.showDetailDrawer = true
      this.$nextTick(() => {
        this.$refs.campaignDetail.loadData(this.detailOptions)
      })
    },
    onDetailDrawerClose () {
      this.showDetailDrawer = false
    },
    // handleAdd (record) {
    //   this.campaignData = {
    //   }
    //   this.campaignData.marketplaceList = []
    //   this.campaignData.siteList = []
    //   // 弹出编辑页面
    //   this.campaignModalShow = true
    // },

    // handleEdit (record) {
    //   const that = this
    //   getCampaignData({ campaignId: record.campaignId }).then(res => {
    //     that.campaignData = res
    //     if (!that.campaignData.marketplaceList) {
    //       this.campaignData.marketplaceList = []
    //     }
    //     if (!that.campaignData.siteList) {
    //       this.campaignData.siteList = []
    //     }
    //   }).catch(res => {
    //     that.$message.error(this.$t('entity.campaign.get.fail'))
    //   })
    //   // 弹出编辑页面
    //   this.campaignModalShow = true
    // },
    handlePause (record) {
      this.$refs['commentEditor'].show(record, 'pause')
    },
    handleRestore (record) {
      this.$refs['commentEditor'].show(record, 'restore')
    },
    handleClose (record) {
      this.$refs['commentEditor'].show(record, 'close')
    },
    handlePublish (record) {
      this.$refs['commentEditor'].show(record, 'publish')
    },
    handleCommentEditOk (params) {
      const record = params.record
      const that = this
      if (params.type === 'disable') {
        return disableCampaign(record)
          .then(res => {
            console.log('handleDisable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('锁定成功')
            return res
          }).catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      } else if (params.type === 'enable') {
        return enableCampaign(record)
          .then(res => {
            console.log('handleEnable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('解除锁定成功')
            return res
          }).catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      } else {
        let campaignStatus = ''
        if (params.type == 'pause') {
          campaignStatus = '已暂停'
        } else if (params.type == 'restore') {
          campaignStatus = '已发布'
        } else if (params.type == 'publish') {
          campaignStatus = '已生效'
        } else if (params.type == 'close') {
          campaignStatus = '已关闭'
        } else {
          return
        }
        return saveCampaignData({
          campaignId: record.campaignId,
          campaignStatus: campaignStatus,
          comment: record.comment
        }).then(res => {
          console.log('saveCampaignData', record, res)
          that.$refs.table.refresh(true)
          that.$message.success('设置状态为【' + campaignStatus + '】成功')
          return res
        }).catch(() => {
          that.$refs.table.refresh(true)
          that.$message.success('设置状态为【' + campaignStatus + '】失败')
        })
      }
    },
    handleDisable (record) {
      this.$refs['commentEditor'].show(record, 'disable')
    },
    handleEnable (record) {
      this.$refs['commentEditor'].show(record, 'enable')
    },
    handleEditCancel (record) {
      // 弹出编辑页面
      this.campaignModalShow = false
    },
    handleEditOk (record) {
      var that = this
      console.debug('handleEditOk')
      saveCampaignData(this.campaignData)
        .then((res) => {
          that.$message.success(this.$t('save.entity.campaign.success'))
        })
        .catch((res) => {
          that.$message.success(this.$t('save.entity.campaign.fail'))
        })
      // 弹出编辑页面
      this.campaignModalShow = false
    },
    handleApproval (record) {
      this.$refs['approvalEditor'].show(record)
    },
    handleApprovalEditOk (params) {
      const record = params.record
      const that = this
      return approvalCampaign({
        approvalInfo: record.comment,
        approvalStatus: record.approvalType === 'reject' ? '已拒绝' : '已通过', /** 待审核, 已通过, 已拒绝, 审核中 */
        campaignId: record.campaignId
      })
        .then(res => {
          console.log('handleApprovalEditOk', record, res)
          that.$refs.table.refresh(true)
          that.$message.success('审批成功')
          return res
        }).catch(() => {
          that.$refs.table.refresh(true)
          that.$message.success('审批失败')
        })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    resetSearchForm (data) {
      this.queryParam = data
      this.$refs.table.refresh(true)
    }
  }
}
</script>
<style lang="less" scoped>
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.list-content-item {
  color: rgba(0, 0, 0, 0.45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;

  span {
    line-height: 20px;
  }

  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
</style>
