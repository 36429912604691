<template>
  <div class="table-page-search-wrapper">
    <a-form layout="inline">
      <a-row :gutter="24">
        
        <a-col :md="8" :sm="24">
          <a-form-item
            :label="$t('field.campaignName')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="[
                'queryParam.campaignName',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.campaignName') },
                    { max: 100, message: this.$t('field.campaignName') + '长度小于等于100' }
                  ]
                }
              ]"
              name="queryParam.campaignName"
              v-model="queryParam.campaignName"
              :placeholder="'请输入' + this.$t('field.campaignName')"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            :label="$t('field.campaignCode')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="[
                'queryParam.campaignCode',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.campaignCode') },
                    { max: 100, message: this.$t('field.campaignCode') + '长度小于等于100' }
                  ]
                }
              ]"
              name="queryParam.campaignCode"
              v-model="queryParam.campaignCode"
              :placeholder="'请输入' + this.$t('field.campaignCode')"
          /></a-form-item>
        </a-col>
        <a-col :md="8" :sm="24" v-if="0">
          <a-form-item
            v-if="showAdvancedSearchCondition"
            :label="$t('field.campaignStatus')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-select :allowClear="true" mode="default" v-model="queryParam.campaignStatus">
              <a-select-option v-for="(item, index) in codeList.status" :key="index" :value="item.value"
                >{{ item.label }}
              </a-select-option>
            </a-select></a-form-item
          >
        </a-col>
        <!--        <template v-if="showAdvancedSearchCondition">-->
        <!--          <a-col :md="8" :sm="24">-->
        <!--            <a-form-item label="更新日期">-->
        <!--              <a-range-picker-->
        <!--                v-model="selectedDate"-->
        <!--                valueFormat="YYYYMMDD"-->
        <!--              />-->
        <!--            </a-form-item>-->
        <!--          </a-col>-->
        <!--        </template>-->
      </a-row>
      <a-row>
        <a-col :md="24" :sm="24">
          <span
            class="table-page-search-submitButtons"
            :style="(showAdvancedSearchCondition && { overflow: 'hidden' }) || {}"
          >
            <a-button icon="plus" type="primary" @click="$emit('handleAdd')">{{ $t('button.create') }}</a-button>
            <a-divider type="vertical" />
            <a-button icon="search" type="primary" @click="queryData">{{ $t('button.search') }}</a-button
            ><a-divider type="vertical" />
            <a-button style="margin-left: 8px" @click="resetData">{{ $t('button.reset') }}</a-button>
            <a @click="toggleAdvancedSearch" style="margin-left: 8px">
              {{ showAdvancedSearchCondition ? $t('button.collapseIn') : $t('button.collapseOut') }}
              <a-icon :type="showAdvancedSearchCondition ? 'up' : 'down'" />
            </a>
          </span>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import moment from 'moment'
import { listMarketplace } from '@/api/system/marketplace'
import { listCampaignType } from '@/api/marketing/campaignType'

export default {
  name: 'CampaignSearch',
  components: {},
  props: {},
  data() {
    return {
      codeList: {
        // campaignTypeList: [],
        campaignCode: [],
        campaignStatus: []
      },
      showAdvancedSearchCondition: '',
      queryParam: {},
      selectedDate: []
    }
  },
  created() {},
  mounted() {
    listMarketplace({}).then(res => {
      this.codeList.marketplaceCode = res.data
    })
    // listCampaignType({}).then(res => {
    //   this.codeList.campaignTypeList = res.data
    // })
  },
  methods: {
    toggleAdvancedSearch() {
      this.showAdvancedSearchCondition = !this.showAdvancedSearchCondition
    },
    resetData() {
      this.queryParam = {}
      this.selectedDate = []
      this.$emit('resetData', this.queryParam)
    },
    queryData() {
      this.$emit('resetData', this.queryParam)
    }
  }
}
</script>
