<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-descriptions bordered title="基本信息">
        <a-descriptions-item label="促销编码">{{ data.campaignCode }}</a-descriptions-item>
        <a-descriptions-item label="促销名称">{{ data.campaignName }}</a-descriptions-item>
        <a-descriptions-item label="促销类型编码">{{ data.campaignTypeCode }}-{{ data.campaignTypeName }}</a-descriptions-item>
        <a-descriptions-item label="促销状态">{{ getCode('campaignStatus', data.campaignStatus) }}</a-descriptions-item>
        <a-descriptions-item label="审批状态">{{ getCode('approvalStatus', data.approvalStatus) }}</a-descriptions-item>
        <a-descriptions-item label="状态">{{ getCode('enableStatus', data.status) }}</a-descriptions-item>
        <a-descriptions-item :label="$t('field.timeLimitType')">{{ data.timeLimitType }}</a-descriptions-item>
        <a-descriptions-item label="开始时间" v-if="data.timeLimitType === '固定期间'">{{ formatDateTime(data.startTime) }}</a-descriptions-item>
        <a-descriptions-item label="结束时间" v-if="data.timeLimitType === '固定期间'||data.timeLimitType === '固定截止时间'">{{ formatDateTime(data.endTime) }}</a-descriptions-item>
        <a-descriptions-item label="结束时间" v-if="data.timeLimitType === '领取后生效'">{{ data.validDateCount }}</a-descriptions-item>
      </a-descriptions>
      <br>
      <a-descriptions bordered title="修改记录">
        <a-descriptions-item label="创建人编码">{{ data.creatorCode }}</a-descriptions-item>
        <a-descriptions-item label="创建人">{{ data.creatorName }}</a-descriptions-item>
        <a-descriptions-item label="创建时间">{{ formatDateTime(data.createTime) }}</a-descriptions-item>
        <a-descriptions-item label="更新人编码">{{ data.updaterCode }}</a-descriptions-item>
        <a-descriptions-item label="更新人">{{ data.updaterName }}</a-descriptions-item>
        <a-descriptions-item label="更新时间">{{ formatDateTime(data.updateTime) }}</a-descriptions-item>
      </a-descriptions>
      <a-descriptions v-if="data.condition" bordered title="适用条件">
        <a-descriptions-item label="管理分类编码">{{ data.condition.categoryCode }}</a-descriptions-item>
        <a-descriptions-item label="品牌编码">{{ data.condition.brandCode }}</a-descriptions-item>
        <a-descriptions-item label="会员级别编码">{{ data.condition.memberGradeCode }}</a-descriptions-item>
      </a-descriptions>
      <a-descriptions v-if="!data.condition" bordered title="适用条件">
        <a-descriptions-item label="管理分类编码">-</a-descriptions-item>
        <a-descriptions-item label="品牌编码">-</a-descriptions-item>
        <a-descriptions-item label="会员级别编码">-</a-descriptions-item>
      </a-descriptions>
      <a-descriptions v-if="data.discount" bordered title="折扣信息">
        <a-descriptions-item label="最小总金额">{{ data.discount.minTotalAmount }}</a-descriptions-item>
        <a-descriptions-item label="价格百分比">{{ data.discount.pricePercent }}</a-descriptions-item>
        <a-descriptions-item label="运费百分比">{{ data.discount.shippingFeeAmountPercent }}</a-descriptions-item>
        <a-descriptions-item label="价格金额">{{ data.discount.priceAmount }}</a-descriptions-item>
        <a-descriptions-item label="运费金额">{{ data.discount.shippingFeeAmount }}</a-descriptions-item>
      </a-descriptions>
      <a-descriptions v-if="!data.discount" bordered title="折扣信息">
        <a-descriptions-item label="最小总金额"></a-descriptions-item>
        <a-descriptions-item label="价格百分比">-</a-descriptions-item>
        <a-descriptions-item label="运费百分比">-</a-descriptions-item>
        <a-descriptions-item label="价格金额">-</a-descriptions-item>
        <a-descriptions-item label="运费金额">-</a-descriptions-item>
      </a-descriptions>
      <a-divider style="margin-bottom: 32px" />
      <div class="title">{{ $t('field.listPicUrl') }}</div>
      <div>
        <img :src="data.listPicUrl" style="width: 200px" />
      </div>
      <a-divider style="margin-bottom: 32px" />
      <div class="title">{{ $t('field.picList') }}</div>
      <div>
        <img
          :src="item"
          alt=""
          v-for="(item, index) in data.picList"
          :key="index"
          style="width: 200px; margin-left: 20px"
        />
      </div>
      <a-divider style="margin-bottom: 32px" />
      <div class="title">{{ $t('field.detailPicDisplayList') }}</div>
      <div>
        <img
          :src="item.url"
          alt=""
          v-for="(item, index) in data.detailPicDisplayList"
          :key="index"
          style="width: 200px; margin-left: 20px"
        />
      </div>
      <div
        v-if="data.marketingConditionType === '品牌SPU'
          ||data.marketingConditionType === '品类SPU'||data.marketingConditionType === '品类品牌SPU'
          ||data.marketingConditionType === 'SPU'">
        <a-divider style="margin-bottom: 32px"/>
        <div class="title">
          <span class="title">spu列表 </span>
        </div>
        <a-table
          v-if="data.spuList && data.spuList.length>0"
          :columns="spuListColumns"
          :dataSource="data.spuList"
          :pagination="false"
          :bordered="true"
          :row-key="(record) => record.spuId"
        >
          <div slot="listPicUrl" slot-scope="text,record">
            <img v-if="text" :src="text" style="max-height: 100px"/>
            <div v-else>
              <img v-if="record.picList && record.picList.length>0" :src="record.picList[0]" style="max-height: 100px"/>
            </div>
          </div>
          <template slot="action" slot-scope="text, record">
            <a-popconfirm :title="$t('msg.confirmDelete')" @confirm="() => onDeleteSpu(record.spuNo)">
              <a href="javascript:;">{{ $t('button.delete') }}</a>
            </a-popconfirm>
          </template>
        </a-table>
      </div>
      <div
        v-if="data.marketingConditionType === '品牌SKU'
          ||data.marketingConditionType === '品类SKU'||data.marketingConditionType === '品类品牌SKU'
          ||data.marketingConditionType === 'SKU'">
        <a-divider style="margin-bottom: 32px"/>
        <div class="title">
          <span class="title">sku列表 </span>
        </div>
        <a-table
          v-if="data.skuList && data.skuList.length>0"
          :columns="skuListColumns"
          :dataSource="data.skuList"
          :pagination="false"
          :bordered="true"
          :row-key="(record) => record.skuId"
        >
          <div slot="listPicUrl" slot-scope="text,record">
            <img v-if="text" :src="text" style="max-height: 100px"/>
            <div v-else>
              <img v-if="record.picList && record.picList.length>0" :src="record.picList[0]" style="max-height: 100px"/>
            </div>
          </div>
        </a-table></div>
      <MultilingualInfo :data="data.multilingualInfoList" :edit="false" @dataChange="onMultilingualDataChanged"></MultilingualInfo>
      <div class="title">{{ $t('field.approvalHistoryList') }}</div>
      <ApprovalHistoryList :approval-history-list="data.approvalInfoList"/>
      <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
        <a-button type="primary" style="margin-left: 8px" @click="handlePreview" icon="play-circle">
          {{ $t('button.preview') }}
        </a-button>
      </a-form-item>
    </a-card>
    <PhonePreview ref="phonePreview"/>
  </page-header-wrapper>

</template>

<script>
import { STable } from '@/components'
import { getCampaignDetail } from '@/api/marketing/campaign'
import ApprovalHistoryList from '@/components/approval/ApprovalHistoryList'
import MultilingualInfo from '@/views/marketing/components/MultilingualInfo'
import PhonePreview from '@/views/component/PhonePreview'
export default {
  name: 'CampaignDetail',
  components: {
    STable,
    ApprovalHistoryList,
    MultilingualInfo,
    PhonePreview
  },
  props: {
    options: {
      type: Object
    }
  },
  created () {
    // getRoleList({t: new Date()})
  },
  mounted () {
    this.loadData({})
  },
  data () {
    return {
      'data': {}
    }
  },
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData (parameter) {
      let params = { campaignId: this.options.campaignId }
      if (parameter && parameter.campaignId) {
        params = parameter
      }
      getCampaignDetail(params)
        .then(res => {
          console.log('loadData->getCampaignDetail.parameter', parameter, res)
          this.data = res // this.data = res.data
          return this.data
        })
    },
    onMultilingualDataChanged () {

    },
    /**
     * 预览
     */
    handlePreview () {
      this.$refs.phonePreview.show('/#/topic/?campaignCode=' + this.data.campaignCode)
    }
  },
  filters: {
    statusFilter (status) {
      const statusMap = {
        'processing': '进行中',
        'success': '完成',
        'failed': '失败'
      }
      return statusMap[status]
    }
  },
  computed: {
    title () {
      return this.$route.meta.title
    }
  }

}
</script>

<style lang="less" scoped>
.title {
  color: rgba(0, 0, 0, .85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}
</style>
